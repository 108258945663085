import {
    Category,
    DomainConfig,
    FilterKeys,
    Location,
    Variation,
} from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { ProductsAndAggregations, Req, SiteProps } from '@zupr/types/next'
import { generateDescription } from '@zupr/utils/categories'

import { getCache, setCache } from './cache'
import { getCategories, getFullpathForSlug } from './categories'
import { getAggregations, getList } from './fo'
import { searchFilters } from './search'

export const aggregationKeys = {
    price: 'product_locations.price_histogram',
    demographic: 'product_locations.product.demographics',
    size: 'product_locations.product.sizes',
    color: 'product_locations.product.colors',
    brand: 'product_locations.product.brand.id',
    materials: 'product_locations.product.materials',
    themes: 'product_locations.themes',
    discount: 'product_locations.is_discount_price',
    deliverable: 'product_locations.allow_delivery',
    collectable: 'product_locations.allow_pay_and_collect',
    reservable: 'product_locations.allow_reservation',
}

export const aggregationExcludeKeys = [
    'product_locations.price__range',
    'product_locations.product.brand.id__in',
]

// these are the filters that are counted
// the keys are used in query string and are "clean" version of the values
export const filterKeys: FilterKeys = {
    price: 'product_locations.price__range',
    color: 'product_locations.product.colors__in',
    size: 'product_locations.product.sizes__in',
    demographic: 'product_locations.product.demographics__in',
    brands: 'product_locations.product.brand.id__in',
    location: 'product_locations.location.id',
    locations: 'product_locations.location.id__in',
    materials: 'product_locations.product.materials__in',
    deliverable: 'product_locations.allow_delivery',
    reservable: 'product_locations.allow_reservation',
    collectable: 'product_locations.allow_pay_and_collect',
    ordering: 'ordering',
    theme: 'product_locations.themes',
    discount: 'product_locations.is_discount_price',
    box: 'product_locations.location.geo_location__bounding_box',
    images: null, // this is a special case handled in getVariables
    stock: null, // this is a special case handled in getVariables
}

export const themes = {
    christmas: 'Kerst',
    black_friday: 'Black Friday',
    new_years_eve: 'Oud & Nieuw',
    saint_nicholas: 'Sinterklaas',
    carnival: 'Carnaval',
    valentines_day: 'Valentijnsdag',
    easter: 'Pasen',
    kings_day: 'Koningsdag',
    mothers_day: 'Moederdag',
    fathers_day: 'Vaderdag',
    back_to_school: 'Back 2 school',
    halloween: 'Halloween',
    saint_martin: 'Sint-Maarten',
    uefa_euro_2020: 'EK Voetbal',
    Christmas: 'Kerst',
    'Black Friday': 'Black Friday',
    "New Year's Eve": 'Oud & Nieuw',
    'Saint Nicholas': 'Sinterklaas',
    Carnival: 'Carnaval',
    'Valentines day': 'Valentijnsdag',
    Easter: 'Pasen',
    "King's day": 'Koningsdag',
    "Mother's day": 'Moederdag',
    "Father's day": 'Vaderdag',
    'Back to school': 'Back 2 school',
    Halloween: 'Halloween',
    'Saint Martin': 'Sint-Maarten',
    'UEFA Euro 2020': 'EK Voetbal',
}

interface GetProducts {
    params: Record<string, string | number | boolean>
    query: SiteProps['query']
    pause?: boolean
}

// --  ✅ Op voorraad
// --  ✅ Voorraad niet bekend
// --  ✅ Niet op voorraad
// --  = `nvt`

// --  ✅ Op voorraad
// --  ✅ Voorraad niet bekend
// --  🔲 Niet op voorraad
// --  = `product_locations.stock__range=0`

// --  ✅ Op voorraad
// --  🔲 Voorraad niet bekend
// --  🔲 Niet op voorraad
// --  = `product_locations.stock__range=1`

// --  🔲 Op voorraad
// --  ✅ Voorraad niet bekend
// --  🔲 Niet op voorraad
// --  = `product_locations.stock_prefs=call_for_stock`

// --  ✅ Op voorraad
// --  🔲 Voorraad niet bekend
// --  ✅ Niet op voorraad
// --  = `product_locations.stock__range=-4&product_locations.stock_prefs__in=in_stock,not_in_stock,exact_stock`

// --  🔲 Op voorraad
// --  🔲 Voorraad niet bekend
// --  ✅ Niet op voorraad
// --  = `product_locations.stock_prefs=not_in_stock`

// --  🔲 Op voorraad
// --  ✅ Voorraad niet bekend
// --  ✅ Niet op voorraad
// --  = `product_locations.stock_prefs__in=not_in_stock,call_for_stock`

// --  🔲 Op voorraad
// --  🔲 Voorraad niet bekend
// --  🔲 Niet op voorraad
// --  = `nvt`

// --  >1 = EXACT stock with a positivive stock count
// --   1 = In stock
// --   0 = Call for stock
// --  -2 = Exact stock, no stock available right now
// --  -3 = Not in stock
// --  -4 = Call for stock without available phone number
// --  -5 = Not active

export const getProductsVariables = async (
    {
        params,
        query,
    }: {
        params: Record<string, string | number | boolean>
        query: SiteProps['query']
    },
    req: Req
) => {
    const activeCategory = await getActiveCategory(
        {
            query,
            params,
        },
        req
    )

    const stockFilter = {}
    if (query.stock) {
        const stock = (query.stock as string).split(',')
        if (stock.length === 1) {
            if (stock.includes('in_stock')) {
                stockFilter['product_locations.stock__range'] = '1'
            }
            if (stock.includes('call_for_stock')) {
                stockFilter['product_locations.stock_prefs__in'] =
                    'call_for_stock'
            }
            if (stock.includes('not_in_stock')) {
                stockFilter['product_locations.stock_prefs'] = 'not_in_stock'
            }
        }
        if (stock.length === 2) {
            if (!stock.includes('in_stock')) {
                stockFilter['product_locations.stock_prefs__in'] =
                    'not_in_stock,call_for_stock'
            }
            if (!stock.includes('call_for_stock')) {
                stockFilter['product_locations.stock__range'] = '-4'
                stockFilter['product_locations.stock_prefs__in'] =
                    'in_stock,not_in_stock,exact_stock'
            }
            if (!stock.includes('not_in_stock')) {
                stockFilter['product_locations.stock__range'] = '-4'
                stockFilter['product_locations.stock_prefs__in'] =
                    'in_stock,call_for_stock,exact_stock'
            }
        }
    }

    let imagesFilter: Record<string, boolean> = {
        'product_locations.product.has_images': true,
    }
    if (query.images) {
        imagesFilter = {}
    }

    const filter = searchFilters({
        filterKeys: {
            ...filterKeys,
            category: activeCategory
                ? `product_locations.product.categories_${
                      activeCategory?.depth - 1
                  }`
                : null,
        },
        ordering: '-product_locations.created', // default ordering
        query: {
            limit: 24, // default limit
            ...query,
            ...params,
            ...imagesFilter,
            ...stockFilter,
            category: activeCategory?.path || null, // change from slug to path
        },
    })

    // special case for category, we dont want to show this in de active filters
    if (activeCategory) {
        filter.filterCount = Math.max(filter.filterCount - 1, 0)
    }

    // special case for negative filters (box)
    if (filter.variables[filterKeys.box] === 'false') {
        delete filter.variables[filterKeys.box]
        filter.filterCount = Math.max(filter.filterCount - 1, 0)
    }

    return filter
}

export const getProducts = async (
    { params, query, pause }: GetProducts,
    req: Req
): Promise<List<Variation>> => {
    const { variables } = await getProductsVariables(
        {
            params,
            query,
        },
        req
    )

    console.log({ variables })

    const [products] = await getList<Variation>(
        {
            url: 'fo/variation',
            variables,
            pause,
        },
        req
    )

    return products
}

const getCategoriesFromAggregations = async (
    { activeCategory, aggregation, params, key },
    req
): Promise<Category[]> => {
    // find the key used for the shopping area slug
    const shoppingAreasKey = Object.keys(params).find((key) =>
        key.includes('shopping_areas')
    )
    const shoppingAreaSlug = params[shoppingAreasKey] as string

    // fetch all categories
    const categories = await getCategories(shoppingAreaSlug, req)

    // find the categories that are in the aggregation
    // and return the categories with the product count
    return categories
        .filter((category) => {
            return aggregation?.buckets?.find((bucket) => {
                if (activeCategory)
                    return (
                        bucket.key === category.path &&
                        bucket.key.startsWith(activeCategory.path)
                    )
                return bucket.key === category.path
            })
        })
        .map((category) => {
            const bucket = aggregation?.buckets?.find((bucket) => {
                return bucket.key === category.path
            })
            return {
                ...category,
                productCount:
                    bucket?.[`reverse.${key}`]?.doc_count || bucket?.doc_count,
            }
        })
}

const getActiveCategory = async (
    { query, params },
    req
): Promise<Category | null> => {
    // find the key used for the shopping area slug
    const shoppingAreasKey = Object.keys(params).find((key) =>
        key.includes('shopping_areas')
    )
    const shoppingAreaSlug = params[shoppingAreasKey] as string

    const categories = await getCategories(shoppingAreaSlug, req)

    // slug of active category
    const categorySlug = query.category || params.category

    // find the category by slug
    const activeCategory =
        !!categorySlug &&
        categories.find((category) => {
            return category.slug === categorySlug
        })

    return activeCategory || null
}

const getCategoryBreadcrumbs = async (
    { category, params },
    req
): Promise<Category[]> => {
    // fetch all categories
    const categories = await getCategories(params.shopping_areas as string, req)
    let categoryBreadcrumbs = []
    if (category) {
        categoryBreadcrumbs = getFullpathForSlug({
            categories,
            slug: category.slug,
        })
    }
    return categoryBreadcrumbs
}

interface GetCategoryDescriptionProps {
    productsAndAggregations: ProductsAndAggregations
    location?: Location
    generate?: boolean
}

export const getCategoryDescription = async ({
    productsAndAggregations,
    location,
    generate = false,
}: GetCategoryDescriptionProps): Promise<string> => {
    const {
        products,
        activeCategory,
        categories,
        categoryBreadcrumbs,
        filter,
    } = productsAndAggregations

    const category = activeCategory.name
    const subCategories = categories.slice(0, 3).map((c) => `${c.name}`)

    const brands = products.results
        .map((p) => p?.product_locations?.[0]?.product?.brand?.title)
        .filter((b) => !!b)
        .filter((b, i, arr) => arr.indexOf(b) === i) // unique
        .slice(0, 3)

    let cacheKey = `description: ${JSON.stringify(
        Object.keys(filter.variables)
            .filter(
                (key) =>
                    key.includes('categories') || key.includes('shopping_areas')
            )
            .sort()
            .map((key) => filter.variables[key])
            .join(',')
    )}`

    if (location) {
        cacheKey = `description: ${JSON.stringify(
            Object.keys(filter.variables)
                .filter(
                    (key) =>
                        key.includes('categories') ||
                        key.includes('location.id')
                )
                .sort()
                .map((key) => filter.variables[key])
                .join(',')
        )}`
    }

    let description = await getCache(cacheKey)

    if (description) return description
    if (!generate) return null

    // generate description if not cached
    description = await generateDescription({
        category,
        subCategories,
        brands,
        parentCategories: categoryBreadcrumbs
            ?.map(({ name }) => name)
            .join(' > '),
        locationTitle: location?.title,
        locationCity: location?.city,
        locationClassification: location?.classificationList
            .map(({ name }) => name)
            .join(', '),
    })

    // add links into description
    brands.forEach((brand) => {
        description = description.replace(
            brand,
            `[${brand}](./${activeCategory.slug}?brands=${
                products.results.find(
                    (p) =>
                        p?.product_locations?.[0]?.product?.brand?.title ===
                        brand
                )?.product_locations?.[0]?.product?.brand?.id
            })`
        )
    })

    subCategories.forEach((subcategory) => {
        description = description.replace(
            subcategory,
            `[${subcategory}](./${
                categories.find((c) => c.name === subcategory).slug
            })`
        )
    })

    setCache(cacheKey, description, 60 * 60 * 24 * 31) // 31 days

    return description
}

export const getProductsAndAggregations = async (
    { params, query }: GetProducts,
    req
): Promise<ProductsAndAggregations> => {
    const activeCategory = await getActiveCategory(
        {
            query,
            params,
        },
        req
    )

    const { variables, filterCount } = await getProductsVariables(
        {
            params,
            query,
        },
        req
    )

    const [products] = await getList<Variation>(
        {
            url: 'fo/variation',
            variables,
        },
        req
    )

    const categoryAggregationKey = `product_locations.product.categories_${
        activeCategory?.depth || 0
    }`

    const aggregations = await getAggregations(
        {
            url: 'fo/variation',
            variables,
            aggregation: {
                base: 'product_locations',
                keys: {
                    ...aggregationKeys,
                    categories: categoryAggregationKey,
                },
                exclude: aggregationExcludeKeys,
            },
        },
        req
    )

    const categories = await getCategoriesFromAggregations(
        {
            aggregation: aggregations.data?.[categoryAggregationKey],
            activeCategory,
            params,
            key: categoryAggregationKey,
        },
        req
    )

    const categoryBreadcrumbs = await getCategoryBreadcrumbs(
        {
            category: activeCategory,
            params,
        },
        req
    )

    return {
        products,
        filter: {
            variables,
            filterCount,
        },
        aggregations,
        activeCategory,
        categoryBreadcrumbs,
        categories,
    }
}

interface GetProductBreadcrumbsProps {
    domainConfig: DomainConfig
    slug: Category['slug']
}

export const getProductBreadcrumbs = async (
    { domainConfig, slug }: GetProductBreadcrumbsProps,
    req: Req
) => {
    const categories = await getCategories(domainConfig.slug, req)

    return getFullpathForSlug({
        categories,
        slug,
    })
}
